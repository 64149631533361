// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-en-js": () => import("./../../../src/pages/blog.en.js" /* webpackChunkName: "component---src-pages-blog-en-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-career-en-js": () => import("./../../../src/pages/career.en.js" /* webpackChunkName: "component---src-pages-career-en-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-casestudies-en-js": () => import("./../../../src/pages/casestudies.en.js" /* webpackChunkName: "component---src-pages-casestudies-en-js" */),
  "component---src-pages-casestudies-js": () => import("./../../../src/pages/casestudies.js" /* webpackChunkName: "component---src-pages-casestudies-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-programmatic-consulting-en-js": () => import("./../../../src/pages/data-programmatic-consulting.en.js" /* webpackChunkName: "component---src-pages-data-programmatic-consulting-en-js" */),
  "component---src-pages-data-programmatic-consulting-js": () => import("./../../../src/pages/data-programmatic-consulting.js" /* webpackChunkName: "component---src-pages-data-programmatic-consulting-js" */),
  "component---src-pages-do-not-sell-my-personal-info-en-js": () => import("./../../../src/pages/do-not-sell-my-personal-info.en.js" /* webpackChunkName: "component---src-pages-do-not-sell-my-personal-info-en-js" */),
  "component---src-pages-do-not-sell-my-personal-info-js": () => import("./../../../src/pages/do-not-sell-my-personal-info.js" /* webpackChunkName: "component---src-pages-do-not-sell-my-personal-info-js" */),
  "component---src-pages-euproject-en-js": () => import("./../../../src/pages/euproject.en.js" /* webpackChunkName: "component---src-pages-euproject-en-js" */),
  "component---src-pages-euproject-js": () => import("./../../../src/pages/euproject.js" /* webpackChunkName: "component---src-pages-euproject-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internet-advertising-privacy-policy-en-js": () => import("./../../../src/pages/internet-advertising-privacy-policy.en.js" /* webpackChunkName: "component---src-pages-internet-advertising-privacy-policy-en-js" */),
  "component---src-pages-internet-advertising-privacy-policy-js": () => import("./../../../src/pages/internet-advertising-privacy-policy.js" /* webpackChunkName: "component---src-pages-internet-advertising-privacy-policy-js" */),
  "component---src-pages-media-en-js": () => import("./../../../src/pages/media.en.js" /* webpackChunkName: "component---src-pages-media-en-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-moc-big-data-w-kampaniach-content-marketingowych-en-js": () => import("./../../../src/pages/moc-big-data-w-kampaniach-content-marketingowych.en.js" /* webpackChunkName: "component---src-pages-moc-big-data-w-kampaniach-content-marketingowych-en-js" */),
  "component---src-pages-moc-big-data-w-kampaniach-content-marketingowych-js": () => import("./../../../src/pages/moc-big-data-w-kampaniach-content-marketingowych.js" /* webpackChunkName: "component---src-pages-moc-big-data-w-kampaniach-content-marketingowych-js" */),
  "component---src-pages-mozliwosci-big-data-w-performance-marketingu-en-js": () => import("./../../../src/pages/mozliwosci-big-data-w-performance-marketingu.en.js" /* webpackChunkName: "component---src-pages-mozliwosci-big-data-w-performance-marketingu-en-js" */),
  "component---src-pages-mozliwosci-big-data-w-performance-marketingu-js": () => import("./../../../src/pages/mozliwosci-big-data-w-performance-marketingu.js" /* webpackChunkName: "component---src-pages-mozliwosci-big-data-w-performance-marketingu-js" */),
  "component---src-pages-opt-out-en-js": () => import("./../../../src/pages/opt-out.en.js" /* webpackChunkName: "component---src-pages-opt-out-en-js" */),
  "component---src-pages-opt-out-js": () => import("./../../../src/pages/opt-out.js" /* webpackChunkName: "component---src-pages-opt-out-js" */),
  "component---src-pages-postepowania-ofertowe-en-js": () => import("./../../../src/pages/postepowania-ofertowe.en.js" /* webpackChunkName: "component---src-pages-postepowania-ofertowe-en-js" */),
  "component---src-pages-postepowania-ofertowe-js": () => import("./../../../src/pages/postepowania-ofertowe.js" /* webpackChunkName: "component---src-pages-postepowania-ofertowe-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-references-en-js": () => import("./../../../src/pages/references.en.js" /* webpackChunkName: "component---src-pages-references-en-js" */),
  "component---src-pages-references-js": () => import("./../../../src/pages/references.js" /* webpackChunkName: "component---src-pages-references-js" */),
  "component---src-pages-regulamin-emisji-reklam-js": () => import("./../../../src/pages/regulamin-emisji-reklam.js" /* webpackChunkName: "component---src-pages-regulamin-emisji-reklam-js" */),
  "component---src-templates-blog-en-js": () => import("./../../../src/templates/blogEN.js" /* webpackChunkName: "component---src-templates-blog-en-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-career-en-js": () => import("./../../../src/templates/careerEN.js" /* webpackChunkName: "component---src-templates-career-en-js" */),
  "component---src-templates-career-js": () => import("./../../../src/templates/career.js" /* webpackChunkName: "component---src-templates-career-js" */),
  "component---src-templates-case-study-en-js": () => import("./../../../src/templates/caseStudyEN.js" /* webpackChunkName: "component---src-templates-case-study-en-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/caseStudy.js" /* webpackChunkName: "component---src-templates-case-study-js" */)
}

